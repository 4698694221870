@import '@styles/_imports';

.button {
    -webkit-appearance: none;
    appearance: none;
    background: 0;
    cursor: pointer;
    display: flex;
    font-family: _v(sans);
    font-size: _v(l2);
    font-weight: _v(regular);
    line-height: 1;
    margin: 0;
    padding: 0;
    outline: 0;
    text-transform: uppercase;
    transition: all _v(transitionDuration);
    align-items: center;

    @include media(tablet) {
        display: inline-flex;
    }

    &:hover {
        color: _v(accent);
    }
}

.borderedButton {
    border: solid 2px;
    display: flex;
    justify-content: center;
    padding: _v(margin-small);
}