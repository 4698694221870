@import 'variables';

:root {
    @each $var, $val in $fonts {
        --#{$var}: #{$val};
    }
    
    @each $var, $val in $utils {
        --#{$var}: #{$val};
    }
}

@each $device, $query in $mediaQueries {
    @if $device != 'landscape' and $device != 'darkMode'  {
        @media #{$query} {
            :root {
                @each $margin, $val in map-get($margins, $device) {
                    --#{"margin-" + $margin}: #{$val};
                }
            }
        }
    }
}

@each $theme, $themeValues in $colors {
    @if $theme == dark {
        @media (prefers-color-scheme: dark) {
            .theme.darkModeEnabled {
                @each $var, $val in $themeValues {
                    --#{"" + $var}: #{$val};
                }
            }
        }
    } @else {
        :root,
        .theme {
            @each $var, $val in $themeValues {
                --#{"" + $var}: #{$val};
            }
        }
    }
}