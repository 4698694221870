@import '@styles/_imports';

.projectDetails {
    color: _v(textLight);
    max-width: 70vw;
}

.client {
    color: _v(background);
    margin-top: 0;
    line-height: 1;
    margin-bottom: _v(margin-medium);
}

.extra {    
    @include media(desktop) {
        border-top: solid 1px;
        margin-top: 40px;
        padding-top: 40px;
    }    
}

.details {
    @include media(desktop) {
        max-width: 70%;
    }
}

.links {
    color: _v(background);
    margin-top: _v(margin-medium);
}