@import '@styles/_imports';
@import '@styles/_keyframes';

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: _z(overlay);
}

.content {
    margin: _v(margin-large);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.overlay-appear-active,
.overlay-enter-active {
    animation-name: in;
    animation-duration: _v(transitionDuration);
    animation-fill-mode: forwards;
}

.overlay-exit-active {
    animation-name: out;
    animation-duration: _v(transitionDuration);
    animation-fill-mode: forwards;
}

.close {
    @include close;

    position: absolute;
    z-index: _z(close);
}