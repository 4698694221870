@import '@styles/_variables';

@mixin bulletless {
    padding-left: 0;
    
    li::before {
        content: none;
    }

    li {
        margin-left: 0;
    }
    
    @content;
}

@mixin blur($amount) {
    /*
    filter: blur($amount);

    // filter: blur performance in Firefox is terrible, so we'll use opacity instead
    @-moz-document url-prefix() {
        filter: none;

        @content;
    }
    */

    @content;
}

@mixin close {
    cursor: pointer;
    display: block;
    font-size: 0;
    line-height: 0;
    height: 32px;
    width: 32px;
    position: absolute;
    right: _v(margin-medium);
    top: _v(margin-medium);

    @media screen and (min-width: device(tablet)) {
        height: 48px;
        width: 48px;
    }

    &::before, &::after {
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        position: absolute;
        background: currentColor;
        transition: all _v(transitionDuration);
    }
    
    &::before {
        transform: translateY(-50%) rotate(45deg);
        margin-top: 50%;
    }
    
    &::after {
        transform: translateY(-50%) rotate(-45deg);
        margin-top: 50%;
    }

}

@mixin media($query) {
    $media: map-get($mediaQueries, $query);

    @media #{$media} {
        @content;
    }
}