@import '@styles/_imports';

.intro {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.introText {
    @include media(desktop) {
        max-width: _v(maxPageWidth);
    }
}