@import '@styles/_imports';

.projectTimeline {
    text-align: center;
    position: relative;

    &::before {
        content: '';
        display: block;
        background: linear-gradient(180deg, _v(shadow) 95%, transparent);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 1px;
    }

    @include media(tablet) {
        text-align: initial;
    }
}

.year {
    background: _v(background);
    color: _v(accent);
    text-align: center;
    position: relative;
    padding: _v(margin-medium) 0;
}
