@import '@styles/_imports';

.logo {
    padding-bottom: _v(margin-medium);
    padding-left: _v(margin-small);    
    padding-right: _v(margin-small);
    padding-top: _v(margin-medium);
    position: absolute;
    top: calc(#{_v(margin-medium)} * -1);
    left: 0;
    transition: all _v(transitionDuration);
}

.logoTextContainer {
    line-height: 1;
}

.logoText {
    letter-spacing: -0.013888888888889em;
    line-height: 0.680555555555556em;
    margin: 0;
    text-transform: uppercase;
    font-weight: _v(light);
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
}