@import '@styles/_imports';

.h1, .intro, .h2, .year, .excerpt, .summary, .book, .h3, .h4, .small, .medium, .regular, .large {
    margin-top: _v(margin-small);
    margin-bottom: 0;

    &:first-child {
        margin-top: 0;
    }
}

.h1 {
    font-family: _v(sans);
    font-size: _v(l4);
    line-height: 1.2;
    font-weight: _v(regular);
    margin-bottom: _v(margin-large);

    @include media(tablet) {
        font-size: _v(l6);
    }
}

.intro {
    font-size: _v(l4);
    font-size: 8vmin;
    line-height: 1.2;
    font-weight: _v(light);
}

.h2 {
    font-family: _v(sans);
    font-size: _v(l5);
    font-weight: _v(light);
}

.year {
    font-family: _v(sans);
    font-size: _v(l5);
    font-weight: _v(light);
}

.excerpt {
    font-size: _v(l5);
    font-weight: _v(light);
}

.summary {
    font-size: _v(l2);
    font-weight: _v(light);

    @include media(tablet) {
        font-size: _v(l4);
    }
}

.book {
    font-size: _v(l2);
    font-weight: _v(light);

    @include media(tablet) {
        font-size: _v(l3);
    }
}

.h3 {
    font-family: _v(sans);
    font-size: _v(l4);
    font-weight: _v(regular);
}

.micro {
    font-family: _v(sans);
    font-size: _v(l0);
}

.small {
    font-family: _v(sans);
    font-size: _v(l1);
}

.regular {
    font-family: _v(sans);
    font-size: _v(l2);
}

.medium {
    font-family: _v(sans);
    font-size: _v(l3);
}

.large {
    font-family: _v(sans);
    font-size: _v(l3);

    @include media(tablet) {
        font-size: _v(l4);
    }
}