@import '@styles/_imports';

.contact,
.qrCode {    
    @include media(desktopLarge) {
        max-width: 70%;
    }
}

.qrCode {
    max-width: 128px;
}

.contactInfo a {
    transition: all _v(transitionDuration);

    &:hover {
        color: _v(accent);
    }
}