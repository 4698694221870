@import '@styles/_imports';

.projectSummary {
    background: _v(background);
    box-sizing: border-box;
    cursor: pointer;
    display: block;    
    margin: _v(margin-large) 0;
    padding: _v(margin-medium);    
    position: relative;

    @include media(tablet) {
        margin: initial;
        width: 50%;

        &.left {
            margin-left: 0;
            padding-right: _v(margin-large);
            text-align: right;
            padding-left: 0;
        }
        
        &.right {
            margin-left: 50%;
            padding-left: _v(margin-large);
            padding-right: 0;
        }
    }

    &:hover,
    &:focus {
        .tags {
            color: _v(accent);
        }
    }
}

.role {
    color: _v(textLight);
    text-transform: lowercase;
}

.tags {
    @include bulletless {}
    
    transition: all _v(transitionDuration);
}