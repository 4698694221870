@import '@styles/_imports';

.about {
    a {
        position: relative;
        transition: all _v(transitionDuration);
        z-index: 1;

        &:link,
        &:visited {
            color: _v(accent);
        }

        &::after {
            background: _v(accent);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0.1;
            position: absolute;
            transform: translateY(-100%);
            transition: all _v(transitionDuration);
            width: 0%;
            z-index: -1;
        }

        &:hover {
            color: _v(accentDark);
    
            &::after {
                width: 100%;
            }
        }
    }
}