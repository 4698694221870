@import '@styles/_imports';

.clients {
    display: flex;
    flex-wrap: wrap;
}

.logo {
    $vMargin: _v(margin-large);
    $hMargin: calc(#{_v(margin-large)} * 2);
    
    padding: $vMargin $hMargin;
    box-sizing: border-box;
    object-fit: contain;

    @include media(tablet) {
        $vMargin: _v(margin-medium);
        $hMargin: calc(#{_v(margin-medium)} * 2);
    
        padding: $vMargin $hMargin;
        width: 50%;        
    }

    @include media(desktop) {
        $vMargin: _v(margin-medium);
        $hMargin: calc(#{_v(margin-medium)} * 2);
    
        padding: $vMargin $hMargin;
        width: 25%;        
    }
}