@import '@styles/_imports';
@import '@styles/_keyframes';

.loader {
    align-items: center;
    background: _v(accent);
    color: _v(background);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: _z(loader);
}

.animation {
    font-size: 32px;
    width: 1em;
    height: 1em;
    margin-right: 0.25em;

    path {
        fill: currentColor;
        stroke: currentColor;
    }
}

.logo {
    font-size: _v(l6);
    color: _v(accentDark);
    padding: 0;
    background: 0;
    position: static;
    margin-bottom: _v(margin-large);
}

.textWrapper {
    display: flex;
    align-items: center;
}

.text {
    margin-top: 0;
}

.loader-appear-active,
.loader-enter-active {
    animation-name: in;
    animation-duration: _v(transitionDuration);
    animation-fill-mode: forwards;
}

.loader-exit-active {
    animation-name: out;
    animation-duration: _v(transitionDuration);
    animation-fill-mode: forwards;
}