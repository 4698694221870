@import '@styles/_variables';

html, body {
    font-family: _v(sans);
    font-size: _v(baseSize);
    font-weight: _v(light);
    line-height: 1.4;
}

* {
    outline: none;
}

a:active,
a:focus,
a:hover,
a:link,
a:visited {
    color: inherit;
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

ol,
ul {
    list-style-type: none;
    padding-left: 1em;
    margin-bottom: 0;

    li {
        margin-left: 1.5em;

        &:before {
            margin-right: 0.5em;
            margin-left: -1.5em;
        }
    }
}

ul li:before {
    content: "\2014";
}

ol {
    counter-reset: item;

    li {
        counter-increment: item;

        &:before {
            content: counter(item) ")";
            margin-left: -1.2em;
        }
    }
}

.quote {
    margin: 4em _v(margin-large);
}

.quoteFooter {
    margin-top: _v(margin-small);
    text-align: right;

    &:before {
        content: "\2014";
        margin-right: 0.5em;
    }
}

.darkModeEnabled .notFoundAnimation {
    @include media(darkMode) {
        filter: invert(1);
    }
}