@import '@styles/_imports';

.footer {
    color: _v(text);
    display: none;

    @include media(tablet) {
        // https://stackoverflow.com/questions/16816648/css-gradient-not-working-on-ios
        background-image: linear-gradient(-2deg, _v(shadow) 0%, _v(background) 45%, rgba(255, 255, 255, 0) 75%);
        
        display: flex;
        line-height: 1;
        margin: 0 auto;
        padding-left: _v(margin-medium);
        padding-right: _v(margin-medium);
        padding-top: _v(margin-large);
        padding-bottom: _v(margin-small);
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: _z(footer);
    }
}

.copyright, .pageNumber {
    margin-bottom: 0;
    margin-top: 0;
}

.copyright {
    margin-left: 0;
    margin-right: auto;
}

.pageNumber {
    margin-left: auto;
    margin-right: 0;    
}

.author {
    color: _v(textLight);
}

.menuLink {
    cursor: pointer;
}