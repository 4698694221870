@import '@styles/_imports';

.menu {
    color: inherit;
}

.menuButton {
    @include close;

    position: fixed;
    z-index: _z(menuButton);
    color: _v(accent);

    &:not(.burger) {
        color: _v(textLight);
    }
}

.burger {
    &::before {
        transform: translateY(-50%) rotate(0deg);
        margin-top: 35%;
    }
    
    &::after {
        transform: translateY(-50%) rotate(0deg);
        margin-top: 65%;
    }
}

.menuList {
    background: _v(text);
    box-sizing: border-box;
    color: _v(textLight);
    position: fixed;
    z-index: _z(menuList);
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: _v(margin-large) _v(margin-medium);
    transform: translateX(100%);
    transition: all _v(transitionDuration);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    &__open {
        transform: translateX(0);
    }
}

.menuItems {
    @include bulletless;

    &:hover {
        color: _v(textMuted);
    }

    li {
        cursor: pointer;
        
        @include media(desktop) {
            margin-right: auto;
        }

        a {
            transition: all _v(transitionDuration);
        }

        &:hover a {
            color: _v(background);
        }
    }
}

.menuItems,
.toolbox {
    min-width: 30vw;
}

.toolbox {
    border-top: solid 1px;
    margin-top: _v(margin-large);
    padding-top: _v(margin-small);
}