@import '@styles/_imports';

.projectOverlay {
    background: _v(text);
    color: _v(background);
}

.projectOverlayCloseButton {
    color: _v(textLight);
}

.projectOverlayContent {
    margin: 0;
    padding: _v(margin-large);
}