@import '@styles/_imports';

.guide {
    position: fixed;
    bottom: calc(#{_v(margin-large)} * 1.5);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.prev {
    margin-right: 0.5em;
}

.next {
    margin-left: 0.5em;
}

.guide > .prev:only-child {
    margin-right: 0;
}

.guide > .next:only-child {
    margin-left: 0;
}