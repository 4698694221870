@import '@styles/_imports';

.servicesColumn {
    break-inside: avoid;
    text-align: center;

    @include media(tablet) {
        text-align: initial;
    }

    + .servicesColumn {
        margin-top: _v(margin-large);

        @include media(tablet) {
            margin-top: 0;
        }
    }
}

.servicesList {
    @include bulletless;

    margin-top: _v(margin-medium);
}

.graded li {
    color: _v(accent);
}