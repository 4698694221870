@import '@styles/_imports';

.master {
    transition: all _v(transitionDuration);
    position: absolute;
    width: 100%;
    height: 100%;
}

.blurred {
    @include blur(_v(blurAmount)) {
        opacity: 0.2;
    }
}

.preventScrolling {
    overflow: hidden;
}

.page {
    background: _v(background);
    color: _v(text);
    position: absolute;
    width: 100%;
    min-height: 100%;
    display: flex;
}

.pageInner {
    margin: _v(margin-large) _v(margin-medium);
    padding: _v(margin-large) 0;
    width: 100%;;
    
    @include media(desktop) {
        max-width: _v(maxPageWidth);
        margin-left: auto;
        margin-right: auto;

        &.bleed {
            max-width: none;
            margin: 0;
            padding: 0;
        }
    }
}

.page-enter-active {
    animation: routerTransitionIn _v(transitionDuration) forwards;
}

.page-exit-active {
    animation: routerTransitionOut _v(transitionDuration) forwards;
}