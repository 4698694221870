.wrapper {
    align-self: center;
    margin: 0 auto;
    text-align: center;
}

.animation {
    display: block;
    height: 300px;
    width: 300px;
    margin: 0 auto;
}

.quote {
    width: 60vw;
}