@import '@styles/_imports';

.rotate {
    background: _c('light', 'background');
    color: _c('light', 'text');
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: _z(loader);
}

.prompt {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}