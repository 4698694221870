@import '@styles/_imports';

.header {
    display: flex;
    position: fixed;
    z-index: _z(header);
    top: _v(margin-medium);
    right: _v(margin-medium);
    left: _v(margin-medium);
}

.logo {
    background: _v(accent);
    color: _v(background);

    @include media(desktop) {
        font-size: _v(l4);
    }
}