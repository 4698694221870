@import '@styles/_imports';

.zoomable .image {
    cursor: zoom-in;
}

.zoomedIn .image {
    cursor: zoom-out;
}

.imageWrapper {
    margin: 0 0 40px;
    transition: all _v(transitionDuration);

    p + &:not(.zoomedIn) {
        margin-top: 40px;
    }
}

.lead {
    float: left;
    margin-right: 40px;
    width: 20vw;
}

.pull {
    position: relative;
    left: -5vw;
    width: 65vw;

    figcaption {
        margin-left: 5vw;
    }
}

.full {
    position: relative;
    left: -20vw;
    margin-right: -20vw;
    width: 100vw;
    max-width: none;
    z-index: 0;

    figcaption {
        margin-left: 20vw;
    }
}

.figure {
    margin: 0;
    position: relative;
}

.image {
    display: block;
    width: 100%;
    height: auto;
} 

.figcaption {
    margin-top: 20px;
    max-width: 60vw;
}

.desc {
    margin: 0;
}

.attrib {
    color: _v(textLight);
    display: block;
    margin-top: 10px;
}

.zoomedIn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: _v(background);
    z-index: 3;
    width: 100%;
    height: 100%;

    .figure {
        display: flex;
        height: 100%;
        width: 100%;
        padding: 40px;
        box-sizing: border-box;

        .image {
            width: auto;
            height: 100%;
            margin-right: 40px;
        }

        .figcaption {
            margin-top: 0;
            width: 20vw;
            margin-left: 0;
        }
    }        
}