@keyframes in {
    from {
        transform: translateY(100%);
    }
    
    to {
        transform: translateY(0%);
    }
}

@keyframes out {
    from {
        transform: translateY(0%);
    }
    
    to {
        transform: translateY(100%);
    }
}

@keyframes routerTransitionIn {
    0% {
        opacity: 0;
        transform: translateX(-10em);
    }

    50% {
        opacity: 0;
    }
    
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes routerTransitionOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        transform: translateX(-10em);
    }
}
